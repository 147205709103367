import { ReactComponent as AiriIcon } from 'assets/icons/airi.svg';
import { ReactComponent as AtomIcon } from 'assets/icons/atom_cosmos.svg';
import { ReactComponent as KwtIcon } from 'assets/icons/kwt.svg';
import { ReactComponent as MilkyIcon } from 'assets/icons/milky-token.svg';
import { ReactComponent as OraiIcon } from 'assets/icons/oraichain.svg';
import { ReactComponent as OraixIcon } from 'assets/icons/oraix.svg';
import { ReactComponent as ScOraiIcon } from 'assets/icons/orchai.svg';
import { ReactComponent as OsmoIcon } from 'assets/icons/osmosis.svg';
import { ReactComponent as TimpiIcon } from 'assets/icons/timpiIcon.svg';
import { ReactComponent as EthIcon } from 'assets/icons/ethereum.svg';
import { ReactComponent as UsdtIcon } from 'assets/icons/tether.svg';
import { ReactComponent as TronIcon } from 'assets/icons/tron.svg';
import { ReactComponent as OraiLightIcon } from 'assets/icons/oraichain_light.svg';
import { ReactComponent as UsdcIcon } from 'assets/icons/usd_coin.svg';
import { ReactComponent as BnbIcon } from 'assets/icons/bnb.svg';
import { ReactComponent as OrchaiIcon } from 'assets/icons/orchaiIcon.svg';
import { ReactComponent as NeutaroIcon } from 'assets/icons/neutaro.svg';
import { ReactComponent as NobleIcon } from 'assets/icons/noble.svg';
import { ReactComponent as InjIcon } from 'assets/icons/inj.svg';
import { ReactComponent as Bitcoin } from 'assets/icons/bitcoin.svg';

import { ROOT_ENV } from 'env';
import {
  AIRI_BSC_CONTRACT,
  AtomToken,
  BTC_CONTRACT,
  InjectiveToken,
  KWT_BSC_CONTRACT,
  KWT_DENOM,
  KWT_SUB_NETWORK_DENOM,
  KawaiiToken,
  MILKY_BSC_CONTRACT,
  MILKY_DENOM,
  MILKY_ERC_CONTRACT,
  MILKY_SUB_NETWORK_DENOM,
  OCH_ETH_CONTRACT,
  ORAIIBC_INJECTIVE_DENOM,
  ORAIX_ETH_CONTRACT,
  ORAI_BRIDGE_EVM_DENOM_PREFIX,
  ORAI_BRIDGE_EVM_ETH_DENOM_PREFIX,
  ORAI_BRIDGE_EVM_TRON_DENOM_PREFIX,
  ORAI_BSC_CONTRACT,
  ORAI_ETH_CONTRACT,
  OraiBToken,
  OsmoToken,
  USDC_ETH_CONTRACT,
  USDT_BSC_CONTRACT,
  USDT_ETH_CONTRACT,
  USDT_TRON_CONTRACT,
  WRAP_BNB_CONTRACT,
  WRAP_ETH_CONTRACT,
  WRAP_TRON_TRX_CONTRACT,
} from '@oraichain/oraidex-common';
import { BridgeAppCurrency, CustomChainInfo, defaultBech32Config } from '@oraichain/oraidex-common';
import { Toncoin } from 'assets/icons';

export type NetworkName = 'Oraichain' | 'Cosmos Hub' | 'Osmosis' | 'Oraichain-testnet';

export type CosmosChainId = 'Oraichain' | 'Oraichain-testnet'; // oraichain

export type NetworkChainId = CosmosChainId;

export type CoinGeckoId =
  | 'oraichain-token'
  | 'osmosis'
  | 'cosmos'
  | 'ethereum'
  | 'bnb'
  | 'airight'
  | 'oraidex'
  | 'tether'
  | 'kawaii-islands'
  | 'milky-token'
  | 'scorai'
  | 'oraidex'
  | 'usd-coin'
  | 'injective'
  | 'tron';

export type NetworkType = 'cosmos';
export type CoinIcon = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

export type CoinType = 118 | 60 | 195;

export const OraiToken: BridgeAppCurrency = {
  coinDenom: 'ORAI',
  coinMinimalDenom: 'orai',
  coinDecimals: 6,
  coinGeckoId: 'oraichain-token',
  Icon: OraiIcon,
  gasPriceStep: {
    low: 0.003,
    average: 0.005,
    high: 0.007,
  },
};

export const oraichainNetwork: CustomChainInfo = {
  rpc: 'https://rpc.orai.io',
  rest: 'https://lcd.orai.io',
  chainId: 'Oraichain',
  chainName: 'Oraichain',
  networkType: 'cosmos',
  stakeCurrency: OraiToken,
  bip44: {
    coinType: 118,
  },
  bech32Config: defaultBech32Config('orai'),
  feeCurrencies: [OraiToken],

  Icon: OraiIcon,
  features: ['ibc-transfer', 'cosmwasm', 'wasmd_0.24+'],
  currencies: [
    OraiToken,
    {
      coinDenom: 'ATOM',
      coinGeckoId: 'cosmos',
      coinMinimalDenom: ROOT_ENV.REACT_APP_ATOM_ORAICHAIN_DENOM,
      coinDecimals: 6,
      Icon: AtomIcon,
    },
    {
      coinDenom: 'AIRI',
      coinGeckoId: 'airight',
      coinMinimalDenom: 'airi',
      contractAddress: ROOT_ENV.REACT_APP_AIRI_CONTRACT,
      coinDecimals: 6,
      Icon: AiriIcon,
    },
    {
      coinDenom: 'USDT',
      coinGeckoId: 'tether',
      coinMinimalDenom: 'usdt',
      type: 'cw20',
      contractAddress: ROOT_ENV.REACT_APP_USDT_CONTRACT || 'orai12hzjxfh77wl572gdzct2fxv2arxcwh6gykc7qh',
      coinDecimals: 6,
      Icon: UsdtIcon,
    },
    {
      coinDenom: 'USDC',
      coinGeckoId: 'usd-coin',
      coinMinimalDenom: 'usdc',
      type: 'cw20',
      contractAddress: ROOT_ENV.REACT_APP_USDC_CONTRACT,

      coinDecimals: 6,
      Icon: UsdcIcon,
    },
    {
      coinDenom: 'OSMO',
      coinMinimalDenom: ROOT_ENV.REACT_APP_OSMOSIS_ORAICHAIN_DENOM,
      coinDecimals: 6,
      coinGeckoId: 'osmosis',
      Icon: OsmoIcon,
    },
    {
      coinDenom: 'BEP20 KWT',
      coinGeckoId: 'kawaii-islands',
      coinMinimalDenom: ROOT_ENV.REACT_APP_KWTBSC_ORAICHAIN_DENOM,
      coinDecimals: 18,
      Icon: KwtIcon,
    },
    {
      coinDenom: 'KWT',
      coinGeckoId: 'kawaii-islands',
      coinMinimalDenom: 'kwt',
      type: 'cw20',
      contractAddress: ROOT_ENV.REACT_APP_KWT_CONTRACT,
      coinDecimals: 6,
      Icon: KwtIcon,
    },
    {
      coinDenom: 'BEP20 MILKY',
      coinGeckoId: 'milky-token',
      coinMinimalDenom: ROOT_ENV.REACT_APP_MILKYBSC_ORAICHAIN_DENOM,
      coinDecimals: 18,
      Icon: MilkyIcon,
    },
    {
      coinDenom: 'MILKY',
      coinGeckoId: 'milky-token',
      coinMinimalDenom: 'milky',
      type: 'cw20',
      contractAddress: ROOT_ENV.REACT_APP_MILKY_CONTRACT,
      coinDecimals: 6,
      Icon: MilkyIcon,
    },
    {
      coinDenom: 'ORAIX',
      coinMinimalDenom: 'oraix',
      type: 'cw20',
      contractAddress: ROOT_ENV.REACT_APP_ORAIX_CONTRACT,
      coinGeckoId: 'oraidex',
      coinDecimals: 6,
      Icon: OraixIcon,
    },
    {
      coinDenom: 'scORAI',
      coinMinimalDenom: 'scorai',
      type: 'cw20',
      contractAddress: ROOT_ENV.REACT_APP_SCORAI_CONTRACT,
      coinGeckoId: 'scorai',
      coinDecimals: 6,
      Icon: ScOraiIcon,
    },
    {
      coinDenom: 'wTRX',
      coinGeckoId: 'tron',
      coinMinimalDenom: 'trx',
      type: 'cw20',
      contractAddress: ROOT_ENV.REACT_APP_TRX_CONTRACT,
      coinDecimals: 6,
      Icon: TronIcon,
    },
    {
      coinDenom: 'INJ',
      coinMinimalDenom: ROOT_ENV.INJECTIVE_ORAICHAIN_DENOM,
      coinDecimals: 18,
      coinGeckoId: 'injective-protocol',
      Icon: InjIcon,
    },
    {
      coinDenom: 'BTC',
      coinMinimalDenom: 'btc',
      type: 'cw20',
      contractAddress: BTC_CONTRACT,
      coinDecimals: 6,
      Icon: Bitcoin,
    },
    {
      coinDenom: 'TON',
      coinMinimalDenom: 'ton',
      type: 'cw20',
      contractAddress: 'TON_CONTRACT',
      coinGeckoId: 'the-open-network' as any,
      coinDecimals: 6,
      Icon: Toncoin,
    },
  ],
};

export const chainInfos: CustomChainInfo[] = [
  oraichainNetwork,
  // {
  //   rpc: 'https://bridge-v2.rpc.orai.io',
  //   rest: 'https://bridge-v2.lcd.orai.io',
  //   chainId: 'oraibridge-subnet-2',
  //   chainName: 'OraiBridge',
  //   networkType: 'cosmos',
  //   bip44: {
  //     coinType: 118,
  //   },
  //   bech32Config: defaultBech32Config('oraib'),

  //   features: ['ibc-transfer'],
  //   stakeCurrency: OraiBToken,
  //   feeCurrencies: [OraiBToken],
  //   // not use oraib as currency
  //   currencies: [
  //     OraiBToken,
  //     {
  //       coinDenom: 'ORAI',
  //       coinMinimalDenom: ORAI_BRIDGE_EVM_DENOM_PREFIX + ORAI_BSC_CONTRACT,
  //       bridgeNetworkIdentifier: '0x38',
  //       coinDecimals: 18,
  //       coinGeckoId: 'oraichain-token',
  //       IconLight: OraiLightIcon,
  //       Icon: OraiIcon,
  //     },
  //     {
  //       coinDenom: 'ORAI',
  //       coinMinimalDenom: ORAI_BRIDGE_EVM_ETH_DENOM_PREFIX + ORAI_ETH_CONTRACT,
  //       bridgeNetworkIdentifier: '0x01',
  //       coinDecimals: 18,
  //       coinGeckoId: 'oraichain-token',
  //       IconLight: OraiLightIcon,
  //       prefixToken: ORAI_BRIDGE_EVM_ETH_DENOM_PREFIX,
  //       Icon: OraiIcon,
  //     },
  //     {
  //       coinDenom: 'USDC',
  //       coinMinimalDenom: ORAI_BRIDGE_EVM_ETH_DENOM_PREFIX + USDC_ETH_CONTRACT,
  //       bridgeNetworkIdentifier: '0x01',
  //       coinDecimals: 6,
  //       coinGeckoId: 'usd-coin',
  //       prefixToken: ORAI_BRIDGE_EVM_ETH_DENOM_PREFIX,
  //       Icon: UsdcIcon,
  //     },
  //     {
  //       coinDenom: 'WETH',
  //       coinMinimalDenom: ORAI_BRIDGE_EVM_ETH_DENOM_PREFIX + WRAP_ETH_CONTRACT,
  //       bridgeNetworkIdentifier: '0x01',
  //       coinDecimals: 6,
  //       coinGeckoId: 'weth',
  //       prefixToken: ORAI_BRIDGE_EVM_ETH_DENOM_PREFIX,
  //       Icon: EthIcon,
  //     },
  //     {
  //       coinDenom: 'AIRI',
  //       coinMinimalDenom: ORAI_BRIDGE_EVM_DENOM_PREFIX + AIRI_BSC_CONTRACT,
  //       bridgeNetworkIdentifier: '0x38',
  //       coinDecimals: 18,
  //       coinGeckoId: 'airight',
  //       Icon: AiriIcon,
  //     },
  //     {
  //       coinDenom: 'USDT',
  //       coinMinimalDenom: ORAI_BRIDGE_EVM_ETH_DENOM_PREFIX + USDT_ETH_CONTRACT,
  //       bridgeNetworkIdentifier: '0x01',
  //       coinDecimals: 6,
  //       coinGeckoId: 'tether',
  //       prefixToken: ORAI_BRIDGE_EVM_ETH_DENOM_PREFIX,
  //       Icon: UsdtIcon,
  //     },
  //     {
  //       coinDenom: 'USDT',
  //       coinMinimalDenom: ORAI_BRIDGE_EVM_DENOM_PREFIX + USDT_BSC_CONTRACT,
  //       bridgeNetworkIdentifier: '0x38',
  //       coinDecimals: 18,
  //       coinGeckoId: 'tether',
  //       Icon: UsdtIcon,
  //     },
  //     {
  //       coinDenom: 'USDT',
  //       coinMinimalDenom: ORAI_BRIDGE_EVM_TRON_DENOM_PREFIX + USDT_TRON_CONTRACT,
  //       bridgeNetworkIdentifier: '0x2b6653dc',
  //       prefixToken: ORAI_BRIDGE_EVM_TRON_DENOM_PREFIX,
  //       coinDecimals: 6,
  //       coinGeckoId: 'tether',
  //       Icon: UsdtIcon,
  //     },
  //     {
  //       coinDenom: 'wTRX',
  //       coinMinimalDenom: ORAI_BRIDGE_EVM_TRON_DENOM_PREFIX + WRAP_TRON_TRX_CONTRACT,
  //       bridgeNetworkIdentifier: '0x2b6653dc',
  //       coinDecimals: 6,
  //       coinGeckoId: 'tron',
  //       prefixToken: ORAI_BRIDGE_EVM_TRON_DENOM_PREFIX,
  //       Icon: TronIcon,
  //     },
  //     {
  //       coinDenom: 'KWT',
  //       bridgeNetworkIdentifier: '0x38',
  //       coinMinimalDenom: KWT_DENOM,
  //       coinDecimals: 18,
  //       coinGeckoId: 'kawaii-islands',
  //       Icon: KwtIcon,
  //     },
  //     {
  //       coinDenom: 'MILKY',
  //       bridgeNetworkIdentifier: '0x38',
  //       coinMinimalDenom: MILKY_DENOM,
  //       coinDecimals: 18,
  //       coinGeckoId: 'milky-token',
  //       Icon: MilkyIcon,
  //     },
  //     {
  //       coinDenom: 'OCH',
  //       coinMinimalDenom: ORAI_BRIDGE_EVM_ETH_DENOM_PREFIX + OCH_ETH_CONTRACT,
  //       bridgeNetworkIdentifier: '0x01',
  //       coinDecimals: 18,
  //       coinGeckoId: 'och',
  //       prefixToken: ORAI_BRIDGE_EVM_ETH_DENOM_PREFIX,
  //       coinImageUrl:
  //         'https://assets.coingecko.com/coins/images/34236/standard/orchai_logo_white_copy_4x-8_%281%29.png?1704307670',
  //     },
  //     {
  //       coinDenom: 'ORAIX',
  //       coinMinimalDenom: ORAI_BRIDGE_EVM_ETH_DENOM_PREFIX + ORAIX_ETH_CONTRACT,
  //       bridgeNetworkIdentifier: '0x01',
  //       coinDecimals: 18,
  //       coinGeckoId: 'oraidex',
  //       prefixToken: ORAI_BRIDGE_EVM_ETH_DENOM_PREFIX,
  //       coinImageUrl: 'https://i.ibb.co/VmMJtf7/oraix.png',
  //     },
  //   ],
  // },
  // {
  //   rpc: 'https://tendermint1.kawaii.global',
  //   rest: 'https://cosmos1.kawaii.global',
  //   chainId: 'kawaii_6886-1',
  //   chainName: 'Kawaiiverse',
  //   networkType: 'cosmos',
  //   stakeCurrency: KawaiiToken,
  //   bip44: {
  //     coinType: 60,
  //   },
  //   bech32Config: defaultBech32Config('oraie'),
  //   feeCurrencies: [KawaiiToken],

  //   Icon: KwtIcon,
  //   // features: ['ibc-transfer'],
  //   features: ['ibc-transfer', 'ibc-go', 'stargate', 'eth-address-gen', 'eth-key-sign'],
  //   currencies: [
  //     KawaiiToken,
  //     {
  //       coinDenom: 'MILKY',
  //       coinGeckoId: 'milky-token',
  //       coinMinimalDenom: MILKY_SUB_NETWORK_DENOM,
  //       coinDecimals: 18,
  //       bridgeTo: ['Oraichain', 'kawaii_6886-1'],
  //       Icon: MilkyIcon,
  //     },
  //     {
  //       coinDenom: 'ERC20 MILKY',
  //       coinMinimalDenom: 'erc20_milky',
  //       bridgeTo: ['Oraichain', 'kawaii_6886-1'],
  //       contractAddress: MILKY_ERC_CONTRACT,
  //       coinDecimals: 18,
  //       coinGeckoId: 'milky-token',
  //       Icon: MilkyIcon,
  //     },
  //     {
  //       coinDenom: 'KWT',
  //       coinMinimalDenom: KWT_SUB_NETWORK_DENOM,
  //       coinDecimals: 18,
  //       bridgeTo: ['Oraichain', 'kawaii_6886-1'],
  //       coinGeckoId: 'kawaii-islands',
  //       Icon: KwtIcon,
  //     },
  //     {
  //       coinDenom: 'ERC20 KWT',
  //       bridgeTo: ['Oraichain', 'kawaii_6886-1'],
  //       coinMinimalDenom: 'erc20_kwt',
  //       contractAddress: '0x80b5a32E4F032B2a058b4F29EC95EEfEEB87aDcd',
  //       coinDecimals: 18,
  //       coinGeckoId: 'kawaii-islands',
  //       Icon: KwtIcon,
  //     },
  //   ],
  // },

  /// popular networks already included
  // {
  //   rpc: 'https://osmosis.rpc.orai.io/',
  //   rest: 'https://osmosis.lcd.orai.io/',
  //   chainId: 'osmosis-1',
  //   chainName: 'Osmosis',
  //   networkType: 'cosmos',
  //   bip44: {
  //     coinType: 118,
  //   },
  //   Icon: OsmoIcon,
  //   IconLight: OsmoIcon,
  //   bech32Config: defaultBech32Config('osmo'),
  //   feeCurrencies: [OsmoToken],
  //   currencies: [
  //     {
  //       coinDenom: 'OSMO',
  //       coinMinimalDenom: 'uosmo',
  //       coinDecimals: 6,
  //       coinGeckoId: 'osmosis',
  //       bridgeTo: ['Oraichain'],
  //       Icon: OsmoIcon,
  //       IconLight: OsmoIcon,
  //     },
  //   ],
  // },
  /// popular networks already included
  // {
  //   rpc: 'https://injective.rpc.orai.io',
  //   rest: 'https://injective.lcd.orai.io',
  //   chainId: 'injective-1',
  //   chainName: 'Injective',
  //   networkType: 'cosmos',
  //   bip44: {
  //     coinType: 60,
  //   },
  //   Icon: InjIcon,
  //   IconLight: InjIcon,
  //   bech32Config: defaultBech32Config('inj'),
  //   feeCurrencies: [InjectiveToken],
  //   currencies: [
  //     {
  //       coinDenom: 'INJ',
  //       coinMinimalDenom: 'inj',
  //       coinDecimals: 18,
  //       coinGeckoId: 'injective-protocol',
  //       bridgeTo: ['Oraichain'],
  //       Icon: InjIcon,
  //       IconLight: InjIcon,
  //     },
  //     {
  //       coinDenom: 'ORAI',
  //       coinMinimalDenom: ORAIIBC_INJECTIVE_DENOM,
  //       coinDecimals: 6,
  //       coinGeckoId: 'oraichain-token',
  //       bridgeTo: ['Oraichain'],
  //       Icon: OraiIcon,
  //       IconLight: OraiLightIcon,
  //     },
  //   ],
  // },
  // {
  //   rpc: 'https://rpc.mainnet.noble.strange.love/',
  //   rest: 'https://noble-api.polkachu.com',
  //   chainId: 'noble-1',
  //   chainName: 'Noble',
  //   networkType: 'cosmos',
  //   bip44: {
  //     coinType: 118,
  //   },
  //   bech32Config: defaultBech32Config('noble'),
  //   features: ['stargate', 'ibc-transfer', 'no-legacy-stdTx', 'ibc-go'],
  //   Icon: NobleIcon,
  //   IconLight: NobleIcon,
  //   currencies: [
  //     {
  //       coinDenom: 'USDC',
  //       coinMinimalDenom: 'uusdc',
  //       coinDecimals: 6,
  //       coinGeckoId: 'usd-coin',
  //       coinImageUrl: 'https://raw.githubusercontent.com/cosmos/chain-registry/master/noble/images/USDCoin.png',
  //       gasPriceStep: {
  //         low: 0,
  //         average: 0.025,
  //         high: 0.03,
  //       },
  //       bridgeTo: ['Oraichain'],
  //       Icon: UsdcIcon,
  //       IconLight: UsdcIcon,
  //     },
  //   ],
  //   feeCurrencies: [
  //     {
  //       coinDenom: 'USDC',
  //       coinMinimalDenom: 'uusdc',
  //       coinDecimals: 6,
  //       coinGeckoId: 'usd-coin',
  //       coinImageUrl: 'https://raw.githubusercontent.com/cosmos/chain-registry/master/noble/images/USDCoin.png',
  //       gasPriceStep: {
  //         low: 0,
  //         average: 0.025,
  //         high: 0.03,
  //       },
  //     },
  //   ],
  //   stakeCurrency: {
  //     coinDecimals: 6,
  //     coinDenom: 'STAKE',
  //     coinMinimalDenom: 'ustake',
  //     coinImageUrl: 'https://raw.githubusercontent.com/cosmos/chain-registry/master/noble/images/stake.png',
  //   },
  //   chainSymbolImageUrl: 'https://raw.githubusercontent.com/cosmos/chain-registry/master/noble/images/stake.png',
  //   txExplorer: {
  //     name: 'Mintscan',
  //     txUrl: 'https://www.mintscan.io/noble/txs/{txHash}',
  //   },
  // },
  // {
  //   // rpc: 'http://rpc.neutaro.tech:26657/',
  //   rpc: 'https://neutaro.rpc.orai.io',
  //   rest: 'https://neutaro.lcd.orai.io',
  //   // rest: 'http://api.neutaro.tech:1317/',
  //   chainId: 'Neutaro-1',
  //   chainName: 'Neutaro',
  //   networkType: 'cosmos',
  //   bip44: {
  //     coinType: 118,
  //   },
  //   Icon: NeutaroIcon,
  //   IconLight: NeutaroIcon,
  //   bech32Config: defaultBech32Config('neutaro'),
  //   stakeCurrency: {
  //     coinDenom: 'ntmpi',
  //     coinMinimalDenom: 'uneutaro',
  //     coinDecimals: 6,
  //     coinImageUrl: 'https://raw.githubusercontent.com/chainapsis/keplr-chain-registry/main/images/Neutaro/chain.png',
  //   },
  //   feeCurrencies: [
  //     {
  //       coinDenom: 'ntmpi',
  //       coinMinimalDenom: 'uneutaro',
  //       coinDecimals: 6,
  //       coinImageUrl: 'https://raw.githubusercontent.com/chainapsis/keplr-chain-registry/main/images/Neutaro/chain.png',
  //       gasPriceStep: {
  //         low: 0.01,
  //         average: 0.025,
  //         high: 0.03,
  //       },
  //     },
  //   ],
  //   currencies: [
  //     {
  //       coinDenom: 'NTMPI',
  //       coinMinimalDenom: 'uneutaro',
  //       coinDecimals: 6,
  //       bridgeTo: ['Oraichain'],
  //       coinGeckoId: 'neutaro',
  //       Icon: TimpiIcon,
  //       IconLight: TimpiIcon,
  //     },
  //   ],
  // },
  // {
  //   rpc: 'https://rpc-cosmos.oraidex.io',
  //   rest: 'https://lcd-cosmos.oraidex.io',
  //   chainId: 'cosmoshub-4',
  //   chainName: 'Cosmos Hub',
  //   networkType: 'cosmos',
  //   bip44: {
  //     coinType: 118,
  //   },
  //   Icon: AtomIcon,
  //   IconLight: AtomIcon,
  //   bech32Config: defaultBech32Config('cosmos'),
  //   feeCurrencies: [AtomToken],
  //   currencies: [
  //     {
  //       coinDenom: 'ATOM',
  //       coinGeckoId: 'cosmos',
  //       coinMinimalDenom: 'uatom',
  //       coinDecimals: 6,
  //       bridgeTo: ['Oraichain'],
  //       Icon: AtomIcon,
  //       IconLight: AtomIcon,
  //     },
  //   ],
  // },

  /// evm chain info
  // {
  //   rpc: 'https://rpc.ankr.com/eth',
  //   chainId: '0x01',
  //   chainName: 'Ethereum',
  //   bip44: {
  //     coinType: 60,
  //   },
  //   Icon: EthIcon,
  //   networkType: 'evm',
  //   currencies: [
  //     {
  //       coinDenom: 'ORAI',
  //       coinMinimalDenom: 'erc20_orai',
  //       contractAddress: ORAI_ETH_CONTRACT,
  //       coinDecimals: 18,
  //       bridgeTo: ['Oraichain'],
  //       coinGeckoId: 'oraichain-token',
  //       IconLight: OraiLightIcon,
  //       prefixToken: ORAI_BRIDGE_EVM_ETH_DENOM_PREFIX,
  //       Icon: OraiIcon,
  //     },
  //     {
  //       coinDenom: 'USDC',
  //       coinMinimalDenom: 'erc20_usdc',
  //       contractAddress: USDC_ETH_CONTRACT,
  //       coinDecimals: 6,
  //       bridgeTo: ['Oraichain'],
  //       coinGeckoId: 'usd-coin',
  //       prefixToken: ORAI_BRIDGE_EVM_ETH_DENOM_PREFIX,
  //       Icon: UsdcIcon,
  //     },
  //     {
  //       coinDenom: 'WETH',
  //       coinMinimalDenom: 'erc20_eth',
  //       contractAddress: WRAP_ETH_CONTRACT,
  //       coinDecimals: 18,
  //       bridgeTo: ['Oraichain'],
  //       coinGeckoId: 'weth',
  //       prefixToken: ORAI_BRIDGE_EVM_ETH_DENOM_PREFIX,
  //       Icon: EthIcon,
  //     },
  //     {
  //       coinDenom: 'ETH',
  //       coinMinimalDenom: 'eth',
  //       contractAddress: '',
  //       coinDecimals: 18,
  //       bridgeTo: ['Oraichain'],
  //       coinGeckoId: 'ethereum',
  //       Icon: EthIcon,
  //       prefixToken: ORAI_BRIDGE_EVM_ETH_DENOM_PREFIX,
  //     },
  //     {
  //       coinDenom: 'USDT',
  //       coinMinimalDenom: 'erc20_usdt',
  //       contractAddress: USDT_ETH_CONTRACT,
  //       coinDecimals: 6,
  //       bridgeTo: ['Oraichain'],
  //       coinGeckoId: 'tether',
  //       prefixToken: ORAI_BRIDGE_EVM_ETH_DENOM_PREFIX,
  //       Icon: UsdtIcon,
  //     },
  //     {
  //       coinDenom: 'OCH',
  //       coinMinimalDenom: 'erc20_och',
  //       contractAddress: OCH_ETH_CONTRACT,
  //       coinDecimals: 18,
  //       bridgeTo: ['Oraichain'],
  //       coinGeckoId: 'och',
  //       prefixToken: ORAI_BRIDGE_EVM_ETH_DENOM_PREFIX,
  //       coinImageUrl:
  //         'https://assets.coingecko.com/coins/images/34236/standard/orchai_logo_white_copy_4x-8_%281%29.png?1704307670',
  //       Icon: OrchaiIcon,
  //     },
  //     {
  //       coinDenom: 'ORAIX',
  //       coinMinimalDenom: 'erc20_oraix',
  //       contractAddress: ORAIX_ETH_CONTRACT,
  //       coinDecimals: 18,
  //       bridgeTo: ['Oraichain'],
  //       coinGeckoId: 'oraidex',
  //       prefixToken: ORAI_BRIDGE_EVM_ETH_DENOM_PREFIX,
  //       Icon: OraixIcon,
  //     },
  //   ],
  // },
  // {
  //   rpc: 'https://api.trongrid.io/jsonrpc',
  //   rest: 'https://api.trongrid.io',
  //   chainId: '0x2b6653dc',
  //   networkType: 'evm',
  //   chainName: 'Tron Network',
  //   Icon: TronIcon,
  //   currencies: [
  //     {
  //       coinDenom: 'USDT',
  //       coinMinimalDenom: 'trx20_usdt',
  //       contractAddress: USDT_TRON_CONTRACT,
  //       bridgeTo: ['Oraichain'],
  //       coinDecimals: 6,
  //       coinGeckoId: 'tether',
  //       prefixToken: ORAI_BRIDGE_EVM_TRON_DENOM_PREFIX,
  //       Icon: UsdtIcon,
  //     },
  //     {
  //       coinDenom: 'wTRX',
  //       coinMinimalDenom: 'trx20_trx',
  //       contractAddress: WRAP_TRON_TRX_CONTRACT,
  //       bridgeTo: ['Oraichain'],
  //       coinDecimals: 6,
  //       coinGeckoId: 'tron',
  //       prefixToken: ORAI_BRIDGE_EVM_TRON_DENOM_PREFIX,
  //       Icon: TronIcon,
  //     },
  //   ],
  //   bip44: {
  //     coinType: 195,
  //   },
  // },
  // {
  //   rpc: 'https://bsc-dataseed1.binance.org',
  //   networkType: 'evm',
  //   Icon: BnbIcon,
  //   chainId: '0x38',
  //   chainName: 'BNB Chain',
  //   bip44: {
  //     coinType: 60,
  //   },
  //   currencies: [
  //     {
  //       coinDenom: 'ORAI',
  //       coinMinimalDenom: 'bep20_orai',
  //       contractAddress: ORAI_BSC_CONTRACT,
  //       bridgeTo: ['Oraichain'],
  //       coinDecimals: 18,
  //       coinGeckoId: 'oraichain-token',
  //       prefixToken: ORAI_BRIDGE_EVM_DENOM_PREFIX,
  //       Icon: OraiIcon,
  //       IconLight: OraiLightIcon,
  //     },
  //     {
  //       coinDenom: 'AIRI',
  //       coinMinimalDenom: 'bep20_airi',
  //       contractAddress: AIRI_BSC_CONTRACT,
  //       bridgeTo: ['Oraichain'],
  //       coinDecimals: 18,
  //       coinGeckoId: 'airight',
  //       prefixToken: ORAI_BRIDGE_EVM_DENOM_PREFIX,
  //       Icon: AiriIcon,
  //     },
  //     {
  //       coinDenom: 'USDT',
  //       coinMinimalDenom: 'bep20_usdt',
  //       contractAddress: USDT_BSC_CONTRACT,
  //       bridgeTo: ['Oraichain'],
  //       coinDecimals: 18,
  //       coinGeckoId: 'tether',
  //       prefixToken: ORAI_BRIDGE_EVM_DENOM_PREFIX,
  //       Icon: UsdtIcon,
  //     },
  //     {
  //       coinDenom: 'KWT',
  //       coinMinimalDenom: 'bep20_kwt',
  //       contractAddress: KWT_BSC_CONTRACT,
  //       bridgeTo: ['Oraichain'],
  //       coinDecimals: 18,
  //       coinGeckoId: 'kawaii-islands',
  //       Icon: KwtIcon,
  //     },
  //     {
  //       coinDenom: 'MILKY',
  //       coinMinimalDenom: 'bep20_milky',
  //       contractAddress: MILKY_BSC_CONTRACT,
  //       coinDecimals: 18,
  //       coinGeckoId: 'milky-token',
  //       bridgeTo: ['Oraichain'],
  //       Icon: MilkyIcon,
  //     },
  //     {
  //       coinDenom: 'WBNB',
  //       coinMinimalDenom: 'bep20_wbnb',
  //       contractAddress: WRAP_BNB_CONTRACT,
  //       coinDecimals: 18,
  //       coinGeckoId: 'wbnb',
  //       bridgeTo: ['Oraichain'],
  //       Icon: BnbIcon,
  //     },
  //     {
  //       coinDenom: 'BNB',
  //       coinMinimalDenom: 'bnb',
  //       contractAddress: '',
  //       coinDecimals: 18,
  //       coinGeckoId: 'binancecoin',
  //       bridgeTo: ['Oraichain'],
  //       prefixToken: ORAI_BRIDGE_EVM_DENOM_PREFIX,
  //       Icon: BnbIcon,
  //     },
  //   ],
  // },
  // {
  //   rpc: 'https://endpoint1.kawaii.global',
  //   chainId: '0x1ae6',
  //   networkType: 'evm',
  //   chainName: 'Kawaiiverse EVM',
  //   Icon: KwtIcon,
  //   bip44: {
  //     coinType: 60,
  //   },
  //   currencies: [
  //     {
  //       coinDenom: 'ERC20 MILKY',
  //       coinMinimalDenom: 'erc20_milky',
  //       bridgeTo: ['Oraichain', 'kawaii_6886-1'],
  //       contractAddress: MILKY_ERC_CONTRACT,
  //       coinDecimals: 18,
  //       coinGeckoId: 'milky-token',
  //       Icon: MilkyIcon,
  //     },
  //     {
  //       coinDenom: 'ERC20 KWT',
  //       bridgeTo: ['Oraichain', 'kawaii_6886-1'],
  //       coinMinimalDenom: 'erc20_kwt',
  //       contractAddress: '0x80b5a32E4F032B2a058b4F29EC95EEfEEB87aDcd',
  //       coinDecimals: 18,
  //       coinGeckoId: 'kawaii-islands',
  //       Icon: KwtIcon,
  //     },
  //   ],
  // },
];
